import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getClinicInfo,
  getClinicListing,
  GetClinicLocations,
  getDoctorsByClinic,
  getStates,
} from "../../../api/clinic-api/Clinic.service";

export interface StateStruct {
  state: string;
  abbrevation: string;
}

export interface AddLocations {
  code: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  myobCode: string;
  name: string;
  postalCode: string;
  status?: string;
}

export interface ClinicStateData {
  id?: string | number;
  clinicName: string;
  add_locations: AddLocations[];
  encryptedClinicId?: string;
  status?: boolean;
}

interface DoctorsByClinicStruct {
  fullName: string;
  jAstatus: string;
  is_form_filled: boolean;
  status: boolean;
}
export interface LocationByClinic {
  encryptID: string;
  locationName: string;
}

interface ClinicState {
  clinics: ClinicStateData[] | [];
  editeclinicData: ClinicStateData | {};
  states: StateStruct[];
  locationsByClinic: LocationByClinic[];
  doctorsByClinic: DoctorsByClinicStruct[];
  totalRecords: number;
  totalRecordsOnDoc: number;
  clinicLoading: boolean;
  clinicStausIsLoading: boolean;
}

const initialState: ClinicState = {
  clinics: [],
  editeclinicData: {},
  states: [],
  locationsByClinic: [],

  doctorsByClinic: [],
  totalRecords: 0,
  totalRecordsOnDoc: 0,
  clinicLoading: false,
  clinicStausIsLoading: false,
};

const ClinicSlice = createSlice({
  name: "clinic",
  initialState,
  reducers: {
    clearEditClinicName: (state) => {
      state.editeclinicData = {};
    },
    clinicTableIsLoading: (state, { payload }) => {
      state.clinicLoading = payload;
    },
    clinicStatusIsLoading: (state, { payload }) => {
      state.clinicStausIsLoading = payload;
    },
    clinicStatusToggle: (state, { payload }) => {
      state.clinics = state.clinics.map((e: any) => {
        if (e.encryptedClinicId === payload) {
          if (e.status === 0) {
            return { ...e, status: 1 };
          } else {
            return { ...e, status: 0 };
          }
        } else {
          return e;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClinicListing.fulfilled, (state, { payload }: any) => {
      state.totalRecords = payload?.result.totalRecords;

      // if (payload.isMobileView && state.clinics.length !== 0) {
      //   console.log(payload.data?.result.allClinics, "hack1");

      //   let formatedDataForMobile = payload.data?.result.allClinics.map(
      //     (e: any, i: number) => ({
      //       id: i,
      //       ...e,
      //     })
      //   );

      //   state.clinics = [...state.clinics, ...formatedDataForMobile];
      // } else {
      //   console.log(payload.data?.result.allClinics, "hack2");
      state.clinics = payload?.result?.allClinics?.map((e: any, i: number) => ({
        id: i,
        ...e,
      }));
      // }
    });
    builder.addCase(getStates.fulfilled, (state, { payload }: any) => {
      state.states = payload.result;
    });
    builder.addCase(GetClinicLocations.fulfilled, (state, { payload }: any) => {
      let datatobechanged = payload.result;
      let datamodified = datatobechanged.map((location: any, id: number) => {
        return { value: location.encryptID, label: location.locationName };
      });
      datamodified = [{ value: "All", label: "All" }, ...datamodified];
      state.locationsByClinic = datamodified;
    });
    builder.addCase(getClinicInfo.fulfilled, (state, { payload }: any) => {
      state.editeclinicData = payload.result;
    });
    builder.addCase(getDoctorsByClinic.fulfilled, (state, { payload }: any) => {
      state.doctorsByClinic = payload.data.data.map((e: any, i: number) => ({
        id: i,
        ...e,
      }));
      state.totalRecordsOnDoc = payload.data.totalRecords;
    });
  },
});

export const ClinicSliceReducer = ClinicSlice.reducer;
export const {
  clinicTableIsLoading,
  clinicStatusIsLoading,
  clearEditClinicName,
  clinicStatusToggle,
} = ClinicSlice.actions;
