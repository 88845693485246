import {
  Button,
  CircularProgress,
  Container,
  Grid,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import React, { useEffect, useState } from "react";
import { array, number, object, string } from "yup";
// import { clinicData, doctorData, servicedata } from "../Data";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import CustomSelect from "../add/CustomSelect";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import "./style.css";
import {
  editDoctorServicesAndFees,
  getAllDoctorServices,
} from "../../../api/fee-management-api/Fee.service";
import { ServiceAndFeesArray } from "../slice/FeeSlice";
import { getClinicListing } from "../../../api/clinic-api/Clinic.service";
import { getDoctorsName } from "../../../api/doctor-api/Api.service";
import { FaPlus } from "react-icons/fa";
export interface AddServiceAndFeeCollection {
  encryptedDoctorId: string;
  encryptedClinicId: string;
  publicHolidayFee: number | null;
  afterHoursFee: number | null;
  weekendFee: number | null;
  servicesAndFees: ServiceAndFeesArray[];
  encryptedDoctorClinicId: string;
}

export interface AddDocServiceFeesPayload {
  data: AddServiceAndFeeCollection;
  callback: Function;
}

const emptyServiceObject: ServiceAndFeesArray = {
  encryptedServicesIds: [],
  fees: null,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function EditFeeScreen() {
  let { id } = useParams();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [flowChecker, setFlowChecker] = useState("");

  const triggerNavigateNormal = () => {
    return navigate(`/feeManagement`);
  };
  const { ClinicState, feeState }: any = useSelector((state: any) => state);
  const triggerNavigateAgreementFlow = () => {
    return navigate(
      `/agreementManagement/add/${feeState?.editeFeeData?.encryptedDoctorId}`
    );
  };

  const ServiceOptions = () => {
    return feeState?.allServices?.map((e: any, i: number) => {
      return { value: e.encryptedServiceId, label: e.serviceName };
    });
  };
  let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;
  const { isMobileView } = useSelector((state: any) => state.globalAppState);

  return (
    <Box>
      <Container
        sx={{ width: "76%", p: 2, ml: 0 }}
        className={"fee-mobile-responsive"}
      >
        <Typography
          variant={isMobileView ? "h5" : "h4"}
          color="inherit"
          mb={2}
          noWrap
        >
          Edit Fee
        </Typography>
        {feeState.feeEditPageLoading ? (
          <Stack spacing={3}>
            {[...Array(2)].map(() => (
              <Grid
                sx={{
                  gap: "30px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                spacing={2}
              >
                <Skeleton
                  variant="rounded"
                  sx={{ height: "3vh", width: "50%" }}
                  animation={"wave"}
                />
                <Skeleton
                  variant="rounded"
                  sx={{ height: "3vh", width: "50%" }}
                  animation={"wave"}
                />
              </Grid>
            ))}
            <div className="add-fee-header">
              <Typography
                variant={isMobileView ? "h6" : "h5"}
                color="inherit"
                noWrap
              >
                Flat Fees
              </Typography>
            </div>
            {[...Array(3)].map(() => (
              <Grid
                sx={{
                  gap: "30px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                spacing={2}
              >
                <Skeleton
                  variant="rounded"
                  sx={{ height: "3vh", width: "50%" }}
                  animation={"wave"}
                />
                <Skeleton
                  variant="rounded"
                  sx={{ height: "3vh", width: "50%" }}
                  animation={"wave"}
                />
              </Grid>
            ))}
            <div className="add-fee-header">
              <Typography
                variant={isMobileView ? "h6" : "h5"}
                color="inherit"
                noWrap
              >
                Service Fees
              </Typography>
            </div>
            <div className="add-more-skeleton">
              {[...Array(2)].map(() => (
                <Grid
                  sx={{
                    gap: "30px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Skeleton
                    variant="rounded"
                    sx={{
                      height: "3vh",
                      width: "50%",
                      marginBottom: "20px",
                    }}
                    animation={"wave"}
                  />
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "3vh", width: "50%" }}
                    animation={"wave"}
                  />
                </Grid>
              ))}
            </div>
            <Grid
              sx={{
                gap: "30px",
                display: "flex",
                justifyContent: "flex-end",
              }}
              spacing={2}
            >
              <Skeleton
                variant="rounded"
                sx={{ height: "6vh", width: "150px" }}
              />
            </Grid>

            <Grid
              sx={{
                gap: "30px",
                display: "flex",
                marginTop: "20px",
                marginLeft: "20px",
              }}
              spacing={2}
            >
              <Skeleton
                variant="rounded"
                sx={{ height: "6vh", width: "150px" }}
              />
              <Skeleton
                variant="rounded"
                sx={{ height: "6vh", width: "150px" }}
              />
            </Grid>
          </Stack>
        ) : (
          <Formik
            initialValues={feeState.editeFeeData}
            validationSchema={object({
              encryptedDoctorId: string().required("Please Select Doctor"),
              encryptedClinicId: string().required("Please Select Clinic"),
              publicHolidayFee: string()
                .test(
                  "is-negative",
                  "Public Holiday Fee Should Not Be Negative",
                  (val: any) => {
                    if (Number(val) < 0) {
                      return false;
                    }
                    return true;
                  }
                )
                .test(
                  "out-of-bound-1",
                  "Public Holiday Fee Must Be Greater Than Or Equal To 1",
                  (val: any) => {
                    if (Number(val) < 1) {
                      return false;
                    }
                    return true;
                  }
                )
                .test(
                  "out-of-bound-100",
                  "Public Holiday Fee Must Be Less Than Or Equal To 100",
                  (val: any) => {
                    if (Number(val) > 100) {
                      return false;
                    }
                    return true;
                  }
                )
                .test(
                  "is-decimal",
                  "Public Holiday Fee Should Contain Only 2 Decimal Points.",
                  (val: any) => {
                    if (val != undefined) {
                      return patternTwoDigisAfterComma.test(val);
                    }
                    return true;
                  }
                )
                .required("Please Enter Public Holiday Fee"),

              weekendFee: string()
                .test(
                  "is-negative",
                  "Weekend Fee Should Not Be Negative",
                  (val: any) => {
                    if (Number(val) < 0) {
                      return false;
                    }
                    return true;
                  }
                )
                .test(
                  "out-of-bound-1",
                  "Weekend Fee Must Be Greater Than Or Equal To 1",
                  (val: any) => {
                    if (Number(val) < 1) {
                      return false;
                    }
                    return true;
                  }
                )
                .test(
                  "out-of-bound-100",
                  "Weekend Fee Must Be Less Than Or Equal To 100",
                  (val: any) => {
                    if (Number(val) > 100) {
                      return false;
                    }
                    return true;
                  }
                )

                .test(
                  "is-decimal",
                  "Weekend Fee should Contain Only 2 decimal points.",
                  (val: any) => {
                    if (val != undefined) {
                      return patternTwoDigisAfterComma.test(val);
                    }
                    return true;
                  }
                )
                .required("Please Enter Weekend Fee"),

              afterHoursFee: string()
                .test(
                  "is-negative",
                  "After Hours Fee Should Not Be Negative",
                  (val: any) => {
                    if (Number(val) < 0) {
                      return false;
                    }
                    return true;
                  }
                )
                .test(
                  "out-of-bound-1",
                  "After Hours Fee Must Be Greater Than Or Equal To 1",
                  (val: any) => {
                    if (Number(val) < 1) {
                      return false;
                    }
                    return true;
                  }
                )
                .test(
                  "out-of-bound-100",
                  "After Hours Fee Must Be Less Than Or Equal To 100",
                  (val: any) => {
                    if (Number(val) > 100) {
                      return false;
                    }
                    return true;
                  }
                )
                .test(
                  "is-decimal",
                  "After Hours Fee should Contain Only 2 decimal points.",
                  (val: any) => {
                    if (val != undefined) {
                      return patternTwoDigisAfterComma.test(val);
                    }
                    return true;
                  }
                )
                .required("Please Enter After Hour Fee"),
              servicesAndFees: array()
                .of(
                  object().shape({
                    encryptedServicesIds: array()
                      .required("required")
                      .min(1, "Please Select Atleast One Service"),
                    fees: string()
                      .test(
                        "is-negative",
                        " Fee Should Not Be Negative",
                        (val: any) => {
                          if (Number(val) < 0) {
                            return false;
                          }
                          return true;
                        }
                      )
                      .test(
                        "out-of-bound-1",
                        " Fee Must Be Greater Than Or Equal To 1",
                        (val: any) => {
                          if (Number(val) < 1) {
                            return false;
                          }
                          return true;
                        }
                      )
                      .test(
                        "out-of-bound-100",
                        " Fee Must Be Less Than Or Equal To 100",
                        (val: any) => {
                          if (Number(val) > 100) {
                            return false;
                          }
                          return true;
                        }
                      )
                      .test(
                        "is-decimal",
                        "Fees should Contain Only 2 decimal points.",
                        (val: any) => {
                          if (val != undefined) {
                            return patternTwoDigisAfterComma.test(val);
                          }
                          return true;
                        }
                      )
                      .required("Please Enter Fee"),
                  })
                )
                .min(1, "Please Provide Atleast 1 Service"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              const payloadData: AddDocServiceFeesPayload = {
                data: {
                  ...values,
                  encryptedDoctorClinicId:
                    feeState?.editeFeeData?.encryptedDoctorClinicId,
                },
                callback:
                  flowChecker === "Special"
                    ? triggerNavigateAgreementFlow
                    : triggerNavigateNormal,
              };
              dispatch(editDoctorServicesAndFees(payloadData));
            }}
          >
            {({ values, errors, isSubmitting, isValid }) => (
              <Box
                ml={0}
                sx={{
                  p: 3,
                  backgroundColor: "#fff ",
                  height: "100%",
                  ml: 0,
                }}
                className={"mobile-responsive"}
              >
                <Form autoComplete="off">
                  <Grid
                    container
                    direction="column"
                    spacing={2}
                    sx={{ width: "95%" }}
                  >
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        type="text"
                        name="encryptedDoctorId"
                        id="encryptedDoctorId"
                        label="Select Doctor"
                        select
                        variant="outlined"
                        required
                        disabled
                        fullWidth
                        size="small"
                      >
                        {feeState?.dropDownDoctors?.map((option: any) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        type="text"
                        name="encryptedClinicId"
                        id="encryptedClinicId"
                        required
                        label="Select Clinic"
                        select
                        variant="outlined"
                        fullWidth
                        size="small"
                      >
                        {ClinicState?.clinics.map((option: any) => (
                          <MenuItem
                            key={option.id}
                            value={option.encryptedClinicId}
                          >
                            {option.clinicName}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <div className="add-fee-header">
                      <Typography
                        variant={isMobileView ? "h6" : "h5"}
                        color="inherit"
                        noWrap
                      >
                        Flat Fees
                      </Typography>
                    </div>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        id="publicHolidayFee"
                        name="publicHolidayFee"
                        size="small"
                        required
                        label="Public Holiday Fees(%)"
                        fullWidth
                        type="number"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        id="afterHoursFee"
                        name="afterHoursFee"
                        size="small"
                        label="After Hour Fees(%)"
                        fullWidth
                        type="number"
                        required
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        id="weekendFee"
                        name="weekendFee"
                        size="small"
                        required
                        label="Weekend Fees(%)"
                        fullWidth
                        type="number"
                        variant="outlined"
                      />
                    </Grid>
                    <div className="add-fee-header">
                      <Typography
                        variant={isMobileView ? "h6" : "h5"}
                        color="inherit"
                        noWrap
                      >
                        Service Fees
                      </Typography>
                    </div>

                    <FieldArray name="servicesAndFees">
                      {({ push, remove }) => (
                        <React.Fragment>
                          {values?.servicesAndFees?.map(
                            (_: any, index: any) => (
                              <div className="add-more-container">
                                <div className="remove-add-more-item">
                                  {values?.servicesAndFees.length > 1 ? (
                                    <span
                                      onClick={() => remove(index)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      X
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <Grid container item key={index} spacing={2}>
                                  <Grid item xs={12}>
                                    <Field
                                      component={CustomSelect}
                                      type="text"
                                      name={`servicesAndFees[${index}].encryptedServicesIds`}
                                      id={`servicesAndFees[${index}].encryptedServicesIds`}
                                      placeholder="Select a service*"
                                      options={ServiceOptions()}
                                      className="custom-select"
                                      isMulti={true}
                                    />
                                    <ErrorMessage
                                      className="error-class-message"
                                      component="div"
                                      name={`servicesAndFees[${index}].encryptedServicesIds`}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Field
                                      fullWidth
                                      size="small"
                                      id={`servicesAndFees[${index}].fees`}
                                      name={`servicesAndFees[${index}].fees`}
                                      component={TextField}
                                      type="number"
                                      required
                                      label="Fees(%)"
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                            )
                          )}

                          <Grid item>
                            {typeof errors.servicesAndFees === "string" ? (
                              <Typography color="error">
                                {errors.servicesAndFees}
                              </Typography>
                            ) : null}
                          </Grid>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "end",
                            }}
                          >
                            <Grid item>
                              <Button
                                disabled={isSubmitting}
                                variant="contained"
                                onClick={() => push(emptyServiceObject)}
                              >
                                <span style={{ marginRight: "3px" }}>
                                  <FaPlus size={10} />
                                </span>
                                Add More
                              </Button>
                            </Grid>
                          </div>
                        </React.Fragment>
                      )}
                    </FieldArray>

                    <div className="clinic-management-add-footer">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          gap: "15px",
                          flexWrap: "wrap",
                          marginTop: "25px",
                          paddingLeft: "15px",
                        }}
                      >
                        <Button
                          disabled={isSubmitting}
                          type="submit"
                          variant="contained"
                          onClick={() => {
                            setFlowChecker("Normal");
                          }}
                          color="primary"
                          startIcon={
                            isSubmitting ? (
                              <CircularProgress size="0.9rem" />
                            ) : undefined
                          }
                        >
                          {isSubmitting ? "Editing" : "Update"}
                        </Button>
                        {!feeState?.editeFeeData?.doctorStatus &&
                          feeState?.editeFeeData?.isDoctorFormFilled && (
                            <Button
                              disabled={isSubmitting}
                              type="submit"
                              onClick={() => {
                                setFlowChecker("Special");
                              }}
                              variant="contained"
                              color="primary"
                              startIcon={
                                isSubmitting ? (
                                  <CircularProgress size="0.9rem" />
                                ) : undefined
                              }
                            >
                              {isSubmitting
                                ? "Submitting"
                                : "Update & Add Agreement"}
                            </Button>
                          )}

                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            triggerNavigateNormal();
                          }}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </div>
                  </Grid>
                </Form>
              </Box>
            )}
          </Formik>
        )}
      </Container>
    </Box>
  );
}
