import {
  GET_FEES,
  ADD_DOC_SER_FEE,
  EDIT_DOC_SER_FEE,
  GET_DOC_SER_FEE,
  GET_DOC_SERVICES,
  DELETE_FEES,
  UPDATE_DOC_FEE_STATUS,
} from "../ActionConstants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toggleLoader, toggleSnacker } from "../../domian/app/AppSlice";
import API from "../Api.utils";
import {
  closeLoaderPayload,
  errorOpen,
  openLoaderPayload,
  successOpen,
} from "../Api.service";
import { AddDocServiceFeesPayload } from "../../pages/feesManagement/add/AddFeeScreen";
import {
  DeleteFeesPayload,
  GetDoctorServicesAndFeesPayload,
  SearchFeePayload,
  UpdateDocFeeStatusPayload,
} from "../../pages/feesManagement/Fee";
import {
  feeAddIsLoading,
  feeEditIsLoading,
  feeStatusIsLoading,
  feeStatusToggle,
  feeTableIsLoading,
} from "../../pages/feesManagement/slice/FeeSlice";
import { getDoctorsName } from "../doctor-api/Api.service";
import { getClinicListing } from "../clinic-api/Clinic.service";

export const getFeesListing = createAsyncThunk(
  GET_FEES,
  async (payload: SearchFeePayload, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(feeTableIsLoading(true));
      const response = await API.get(
        `DoctorServicesAndFees/AllDoctorsAndClinics?PageSize=${payload.PageSize}&PageNumber=${payload.PageNumber}&SearchText=${payload.SearchText}&status=${payload.status}`,
        { signal }
      );
      const data = response.data;
      //dispatch(toggleSnacker(successOpen("sucessfully login")));
      return data;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error Fetching Fee Details, Please Try Again"
          )
        )
      );
      dispatch(feeTableIsLoading(false));
      return err;
    } finally {
      dispatch(feeTableIsLoading(false));
    }
  }
);

export const UpdateFeeStatus = createAsyncThunk(
  UPDATE_DOC_FEE_STATUS,
  async (postData: UpdateDocFeeStatusPayload, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(feeStatusIsLoading(true));

      const response = await API.post(
        `DoctorServicesAndFees/UpdateFeeStatus`,
        postData.data,
        {
          signal,
        }
      );
      dispatch(feeStatusToggle(postData.callback));
      const data = response.data;
      dispatch(
        toggleSnacker(
          successOpen(
            data?.responseMessage
              ? data?.responseMessage
              : "Fee Status Updated Sucessfully"
          )
        )
      );
      return data;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error In Updating Fee Status, Please Try Again"
          )
        )
      );
      dispatch(feeStatusIsLoading(false));
      return err;
    } finally {
      dispatch(feeStatusIsLoading(false));
    }
  }
);

export const addDoctorServicesAndFees = createAsyncThunk(
  ADD_DOC_SER_FEE,
  async (postData: AddDocServiceFeesPayload, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.post(
        `DoctorServicesAndFees/AddDoctorServicesAndFees`,
        postData.data,
        { signal }
      );
      postData.callback();

      const data = response.data;
      dispatch(
        toggleSnacker(
          successOpen(
            data?.responseMessage ? data?.responseMessage : "Added Sucessfully"
          )
        )
      );
      return data;
    } catch (err: any) {
      if (err?.response?.data?.responseCode === "409") {
        dispatch(
          toggleSnacker(
            errorOpen(
              err?.response?.data?.responseMessage
                ? err?.response?.data?.responseMessage
                : "Error In Adding Doctor Services And Fees, Please Try Again"
            )
          )
        );
      } else if (err?.response?.data?.responseCode === "400") {
        let msg = ``;

        for (const key in err?.response?.data?.result) {
          msg += `${key}:${err?.response?.data?.result[key]}\n`;
        }
        dispatch(toggleSnacker(errorOpen(msg)));
      } else {
        dispatch(
          toggleSnacker(
            errorOpen(
              err?.response?.data?.responseMessage
                ? err?.response?.data?.responseMessage
                : "Error In Adding Doctor Services And Fees, Please Try Again"
            )
          )
        );
      }

      dispatch(toggleLoader(closeLoaderPayload));
      return err;
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);

export const editDoctorServicesAndFees = createAsyncThunk(
  EDIT_DOC_SER_FEE,
  async (postData: AddDocServiceFeesPayload, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.patch(
        `DoctorServicesAndFees/UpdateDoctorServicesAndFees`,
        postData.data,
        { signal }
      );
      postData.callback();
      const data = response.data;
      dispatch(
        toggleSnacker(
          successOpen(
            data?.responseMessage
              ? data?.responseMessage
              : "Successfully Updated Fee"
          )
        )
      );
      return data;
    } catch (err: any) {
      if (err?.response?.data?.responseCode === "409") {
        dispatch(
          toggleSnacker(
            errorOpen(
              err?.response?.data?.responseMessage
                ? err?.response?.data?.responseMessage
                : "Error In Editing Doctor Servies And Fees, Please Try Again"
            )
          )
        );
      } else if (err?.response?.data?.responseCode === "400") {
        for (const key in err?.response?.data?.result) {
          dispatch(
            toggleSnacker(
              errorOpen(`${key}: ${err?.response?.data?.result[key]}`)
            )
          );
        }
      } else {
        dispatch(
          toggleSnacker(
            errorOpen(
              err?.response?.data?.responseMessage
                ? err?.response?.data?.responseMessage
                : "Error In Editing Doctor Servies And Fees, Please Try Again"
            )
          )
        );
      }

      dispatch(toggleLoader(closeLoaderPayload));
      return err;
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);

export const getDoctorServicesAndFees = createAsyncThunk(
  GET_DOC_SER_FEE,
  async (payload: GetDoctorServicesAndFeesPayload, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      const response = await API.get(
        `DoctorServicesAndFees/DoctorServicesAndFees?EncryptedDoctorClinicId=${payload.data?.EncryptedDoctorClinicId}`,
        { signal }
      );
      const data = response.data;
      //dispatch(toggleSnacker(successOpen("sucessfully login")));
      return data;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error In Fetching Fee Details, Please Try Again"
          )
        )
      );
      return err;
    } finally {
    }
  }
);

export const getAllDoctorServices = createAsyncThunk(
  GET_DOC_SERVICES,
  async (_, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      const response = await API.get(
        `DoctorServicesAndFees/AllDoctorServices`,
        { signal }
      );
      const data = response.data;
      //dispatch(toggleSnacker(successOpen("sucessfully login")));
      return data;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error, Please Try Again"
          )
        )
      );
      return err;
    } finally {
    }
  }
);

export const deleteFees = createAsyncThunk(
  DELETE_FEES,
  async (postData: DeleteFeesPayload, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.delete(
        `DoctorServicesAndFees/DeleteFee?EncryptedDoctorClinicId=${postData.data.EncryptedDoctorClinicId}`,
        {
          signal,
        }
      );
      postData.callback();
      const data = response.data;
      dispatch(
        toggleSnacker(
          successOpen(
            data?.responseMessage
              ? data?.responseMessage
              : "Deleted Fee Sucessfully"
          )
        )
      );
      return data;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error In Deleting Fees, Please Try Again"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return err;
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);

export const feeAddAllApiResolve = createAsyncThunk(
  "FEE_ADD_API",
  async (_, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(feeAddIsLoading(true));
      await Promise.all([
        dispatch(getAllDoctorServices()),
        dispatch(getDoctorsName()),
        dispatch(
          getClinicListing({
            status: 0,
            SkipPagination: true,
          })
        ),
      ]);
    } catch (error) {
      dispatch(feeAddIsLoading(false));
      return [];
    } finally {
      dispatch(feeAddIsLoading(false));
    }
  }
);

export const feeEditAllApiResolve = createAsyncThunk(
  "FEE_EDIT_API",
  async (payload: GetDoctorServicesAndFeesPayload, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(feeEditIsLoading(true));
      payload.callback();

      await Promise.all([
        dispatch(getAllDoctorServices()),
        dispatch(getDoctorsName()),
        dispatch(
          getClinicListing({
            status: 0,
            SkipPagination: true,
          })
        ),
        dispatch(getDoctorServicesAndFees(payload)),
      ]);
    } catch (error) {
      dispatch(feeEditIsLoading(false));
      return [];
    } finally {
      dispatch(feeEditIsLoading(false));
    }
  }
);
