import {
  Button,
  Container,
  Grid,
  List,
  ListItem,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./styles.css";
import Select, { SelectChangeEvent } from "@mui/material/Select/Select";
import { Box } from "@mui/material";
import { AppDispatch, RootState } from "../../store/store";
import {
  agreementAllApiResolve,
  getDoctorData,
  getDoctorNameList,
  saveAgreement,
} from "../../api/agreement-api/Agreement.service";
import { useDispatch, useSelector } from "react-redux";
import {
  AgreementDoctorData,
  AgreementStateData,
  DoctorNameList,
} from "./types";
import {
  DoctorList,
  clearAgreementDoctorDataAgreements,
} from "./slice/AgreementSlice";

const emptyServiceObject = {
  selectServices: "",
  ManagementFees: "",
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export interface AddAgreementPayload {
  data: string;
  callback: Function;
}

interface Values {
  selectDoctor: string;
}

export function AddAgreement() {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch: AppDispatch = useDispatch();
  const { doctorNameList } = useSelector(
    (state: RootState) => state.agreementState
  );
  const { agreementDoctorData, addAgreementIsLoading } = useSelector(
    (state: RootState) => state.agreementState
  );
  const [doctorName, setDoctorName] = useState<DoctorNameList[]>([]);

  useEffect(() => {
    if (params.id) {
      dispatch(agreementAllApiResolve(params.id));
    } else {
      dispatch(getDoctorNameList());
    }
    return () => {
      dispatch(clearAgreementDoctorDataAgreements());
    };
  }, []);

  const handleChange = (event: SelectChangeEvent<string>, fieldName: any) => {
    const {
      target: { value },
    } = event;
    dispatch(getDoctorData(value));
    fieldName("selectDoctor", value);
  };

  const createList = (services: string) => {
    const serviceList = services.split(": ,");
    return (
      <List>
        {serviceList.map((service: string, index: number) => {
          return <ListItem key={index}> ◾ {service}</ListItem>;
        })}
      </List>
    );
  };
  const { isMobileView } = useSelector((state: any) => state.globalAppState);

  return (
    <Box>
      <Container sx={{ width: "100%", p: 2, ml: 0 }}>
        <Typography
          variant={isMobileView ? "h5" : "h4"}
          color="inherit"
          mb={2}
          noWrap
        >
          Add Agreement
        </Typography>

        <Formik
          initialValues={{
            selectDoctor: params.id ? params.id : "",
            doctorNameList: [],
            agreementDoctorData,
          }}
          validate={(values) => {
            const errors: Partial<Values> = {};
            if (values.selectDoctor == "") {
              errors.selectDoctor = "Please select doctor";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            const navigateback = () => {
              return navigate(`/agreementManagement`);
            };
            const payloadData = {
              data: values.selectDoctor,
              callback: navigateback,
            };
            dispatch(saveAgreement(payloadData));
          }}
        >
          {({
            values,
            submitForm,
            errors,
            isSubmitting,
            isValid,
            setFieldValue,
          }) => (
            <Box
              ml={0}
              sx={{
                p: 3,
                backgroundColor: "#fff ",
                height: "100%",
                ml: 0,
              }}
            >
              {addAgreementIsLoading ? (
                <>
                  <Stack spacing={3}>
                    <Skeleton
                      variant="rounded"
                      sx={{ height: "5vh", width: "100%" }}
                      animation={"pulse"}
                    />
                    {[...Array(3)].map(() => (
                      <Grid
                        sx={{
                          gap: "30px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                        spacing={2}
                      >
                        ◾
                        <Skeleton
                          variant="rounded"
                          sx={{ height: "3", width: "70%" }}
                          animation={"wave"}
                        />
                      </Grid>
                    ))}
                    <Grid
                      sx={{
                        gap: "30px",
                        display: "flex",
                        marginTop: "20px",
                      }}
                      spacing={2}
                    >
                      <Skeleton
                        variant="rounded"
                        sx={{ height: "6vh", width: "150px" }}
                      />
                    </Grid>
                  </Stack>
                </>
              ) : (
                <Form autoComplete="off">
                  <Grid container direction="column" spacing={2}>
                    <Grid item xs={12} width={"100%"}>
                      <Field
                        component={TextField}
                        required
                        type="text"
                        name="selectDoctor"
                        id="selectDoctor"
                        label="Select Doctor"
                        select
                        /* value={selectedDoctor} */
                        onChange={(event: any) =>
                          handleChange(event, setFieldValue)
                        }
                        variant="outlined"
                        fullWidth
                        size="small"
                      >
                        {Array.isArray(doctorNameList) &&
                          doctorNameList?.map(
                            (option: DoctorNameList, index: number) => (
                              <MenuItem key={index} value={option?.encryptedId}>
                                {option?.firstName} {option?.lastName}
                              </MenuItem>
                            )
                          )}
                      </Field>
                    </Grid>
                    {Object.keys(agreementDoctorData)?.length !== 0 &&
                      values.selectDoctor && (
                        <>
                          <Box
                            sx={{ width: "100%", maxWidth: 600 }}
                            mt={2}
                            ml={2}
                          >
                            <Typography variant="h6" gutterBottom>
                              Contract Information
                            </Typography>
                          </Box>
                          <Box
                            sx={{ width: "100%", maxWidth: 600 }}
                            mt={1}
                            ml={2}
                          >
                            <Typography variant="body2" gutterBottom>
                              <strong>Full Name: </strong>
                              {agreementDoctorData?.firstName}{" "}
                              {agreementDoctorData?.middleName
                                ? agreementDoctorData?.middleName
                                : ""}{" "}
                              {agreementDoctorData?.lastName}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              <strong>ABN: </strong>
                              {agreementDoctorData?.abn}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              <strong>Email: </strong>
                              {agreementDoctorData?.email}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              <strong>Phone: </strong>
                              {agreementDoctorData?.mobile}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              <strong>Consultation Service Charges: </strong>
                              {agreementDoctorData?.consultationServiceCharges
                                ? createList(
                                    agreementDoctorData?.consultationServiceCharges
                                  )
                                : ""}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              <strong>Account Name: </strong>
                              {agreementDoctorData?.accountName}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              <strong>Account Number: </strong>
                              {agreementDoctorData?.accountNumber}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              <strong>Bsb Number: </strong>
                              {agreementDoctorData?.bsbNumber}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              <strong>Financial Institution Name: </strong>
                              {agreementDoctorData?.financialInstitutionName}
                            </Typography>
                          </Box>
                        </>
                      )}

                    <Box sx={{ width: "100%", maxWidth: 600 }} mt={1} ml={2}>
                      {Object.keys(agreementDoctorData).length !== 0 &&
                        values.selectDoctor && (
                          <>
                            <Button
                              variant="contained"
                              sx={{ mt: 3 }}
                              style={{
                                marginRight: "20px",
                                marginBottom: "20px",
                              }}
                              disabled={isSubmitting}
                              onClick={submitForm}
                            >
                              Save
                            </Button>
                          </>
                        )}
                      <Button
                        variant="outlined"
                        sx={{ mt: 3, ml: 1, mx: 2 }}
                        style={{
                          marginLeft: "0",
                          marginBottom: "20px",
                        }}
                        onClick={() => navigate(`/agreementManagement`)}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Grid>
                </Form>
              )}
            </Box>
          )}
        </Formik>
      </Container>
    </Box>
  );
}
