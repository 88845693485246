export const data11 = [
  {
    encrypted_ID: "1",
    lastname: "Snow",
    firstname: "Jon",
    jAstatus: "hired",
    abn: "123456",
  },
  {
    encrypted_ID: "2",
    lastname: "Lannister",
    firstname: "Cersei",
    jAstatus: "active",
    abn: "123456",
  },
  {
    encrypted_ID: "3",
    lastname: "Lannister",
    firstname: "Jaime",
    jAstatus: "offered",
    abn: "123456",
  },
  {
    encrypted_ID: "4",
    lastname: "Stark",
    firstname: "Arya",
    jAstatus: "active",
    abn: "123456",
  },
  {
    encrypted_ID: "5",
    lastname: "Targaryen",
    firstname: "Daenerys",
    jAstatus: "offered",
    abn: "123456",
  },
  {
    encrypted_ID: "6",
    lastname: "Melisandre",
    firstname: "Daenerys",
    jAstatus: "hired",
    abn: "123456",
  },
  {
    encrypted_ID: "7",
    lastname: "Clifford",
    firstname: "Ferrara",
    jAstatus: "active",
    abn: "123456",
  },
  {
    encrypted_ID: "8",
    lastname: "Frances",
    firstname: "Rossini",
    jAstatus: "offered",
    abn: "123456",
  },
  {
    encrypted_ID: "9",
    lastname: "Roxie",
    firstname: "Harvey",
    jAstatus: "active",
    abn: "123456",
  },
];

export const data = [
  {
    id: "kQymYZbIqiVQbsPDDPopaQ==",
    jAstatus: "Offered",
    firstname: "Anurag",
    middlename: "N",
    lastname: "Shukla",
    dateofbirth: "1996-08-16T00:00:00",
    mobile: "12345678",
    email: "anurag.shukla@classicinformatics.com",
    address: "Pune",
    city: "Pune",
    state: "MH",
    postalcode: "123456",
    abn: "XYZ123",
    abnassociatedname: "ABC1234",
    ahpraregistrationnumber: "ASDF567",
    prescribernumber: "98765AB",
    currentorpreviousprovidernumber: "QWER5667",
    created_at: "2023-03-03T04:32:03.97"
},
{
    id: "bvWaNPwUQfP_wBUQB1IFow==",
    jAstatus: "Offered",
    firstname: "Saurabh",
    middlename: "",
    lastname: "Gupta",
    dateofbirth: "1993-08-12T00:00:00",
    mobile: "12345678",
    email: "saurabh.gupta@classicinformatics.com",
    address: "Pune",
    city: "Pune",
    state: "MH",
    postalcode: "12345",
    abn: "XYZ123",
    abnassociatedname: "ABC1234",
    ahpraregistrationnumber: "ASDF567",
    prescribernumber: "98765AB",
    currentorpreviousprovidernumber: "QWER5667",
    created_at: "2023-03-03T12:07:55.687"
},
{
    id: "-35XO9r2URBbieXFl9epEw==",
    jAstatus: "Offered",
    firstname: "Rupali",
    middlename: "",
    lastname: "Mahajan",
    dateofbirth: "1990-08-11T00:00:00",
    mobile: "12345678",
    email: "rupali.mahajan@classicinformatics.com",
    address: "Mohali",
    city: "Mohali",
    state: "Punjab",
    postalcode: "12345",
    abn: "XYZ123",
    abnassociatedname: "ABC1234",
    ahpraregistrationnumber: "ASDF567",
    prescribernumber: "98765AB",
    currentorpreviousprovidernumber: "QWER5667",
    created_at: "2023-03-03T12:09:02.393"
},
]
export const intialDoctorData={
  jAstatus: "",
  firstname: "",
  middlename: "",
  lastname: "",
  dateofbirth: new Date(),
  mobile: "",
  email: "",
  address: "",
  city: "",
  state: "",
  postalcode: "",
  abn: "",
  abnassociatedname: "",
  ahpraregistrationnumber: "",
  prescribernumber: "",
  currentorpreviousprovidernumber: "",
  
}

export const status = [
  {
    label: "All",
    value: "All",
  },
  {
    label: "Offered",
    value: "Offered",
  },
  {
    label: "Hired",
    value: "Hired",
  },
];
export const NstatusData = [
  {
    label: "None",
    value: "None",
  },
  {
    label: "Active",
    value: "Active",
  },
  {
    label: "Inactive",
    value: "Inactive",
  },
];

export const filterData = [
  {
    id: "1",
    label: "Seach",
    value: "SEARCH",
    checked: true
  },
  {
    id: "2",
    label: "Seach",
    value: "JASTATUS",
    checked: false
  },
  {
    id: "3",
    label: "Seach",
    value: "STATUS",
    checked: false
  },
  {
    id: "4",
    label: "Seach",
    value: "CLINICNAME",
    checked: false
  },
];