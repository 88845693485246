import React, { useState } from "react";
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Modal,
  Switch,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ListIcon from "@mui/icons-material/List";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { MdDone } from "react-icons/md";
import HandshakeIcon from "@mui/icons-material/Handshake";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DownloadIcon from "@mui/icons-material/Download";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store/store";
import AddBoxIcon from "@mui/icons-material/AddBox";

import {
  UpdateClinicStatus,
  deleteClinic,
  getClinicInfo,
  getClinicListing,
} from "../../api/clinic-api/Clinic.service";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { Link, useNavigate } from "react-router-dom";
import { DeleteClinicPayload } from "../../pages/clinicManagement/ClinicScreen";
import { RxCross2 } from "react-icons/rx";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  changeStatus,
  deleteDoctor,
  dotorEditAllApiResolve,
  searchDoctor,
} from "../../api/doctor-api/Api.service";
import {
  UpdateFeeStatus,
  deleteFees,
  feeEditAllApiResolve,
  getFeesListing,
} from "../../api/fee-management-api/Fee.service";
import { viewAgreementData } from "../../api/agreement-api/Agreement.service";
import "./style.css";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 250,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

const boldShade = "rgb(60, 60, 60)";

export const ClinicMobileCompoennt = ({
  clinic,
  searchText,
  statusState,
  pageState,
}: any) => {
  const [open, setOpen] = useState(false);
  const [deleteActionData, SetDeleteActionData] = useState<any>({});

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    let { encryptedClinicId, status } = clinic;

    let statusUpdatePayload;
    if (status === 1) {
      statusUpdatePayload = {
        callback: encryptedClinicId,
        data: { encryptedClinicId: encryptedClinicId, status: 0 },
      };
    } else {
      statusUpdatePayload = {
        callback: encryptedClinicId,
        data: {
          encryptedClinicId: encryptedClinicId,
          status: 1,
        },
      };
    }
    dispatch(UpdateClinicStatus(statusUpdatePayload));
  };
  const onEdit = () => {
    const navigateback = () => {
      return navigate(`/clinicManagement/${clinic.encryptedClinicId}`);
    };
    let editClinicInfoPayload = {
      callback: navigateback,
      data: {
        EncryptedClinicId: clinic.encryptedClinicId,
      },
    };
    dispatch(getClinicInfo(editClinicInfoPayload));
  };
  const onDelete = (e: any) => {
    SetDeleteActionData(clinic);
    handleOpen();
  };

  const listDocs = () => {
    navigate(`/clinicManagement/listofdoctors/${clinic.encryptedClinicId}`, {
      state: { clinicName: clinic?.clinicName },
    });
  };

  const locationNames = () => {
    let name = "";
    clinic?.clinicLocations?.map((clinic: any, key: any) => {
      if (key !== 0) {
        name += ", ";
      }
      name += clinic.name;
    });
    return name;
  };

  return (
    <>
      <div className="details-container">
        <Grid
          container
          p={"10px"}
          sx={{ flexDirection: "column", gap: "10px" }}
          flex={3}
        >
          <Typography variant="subtitle2" sx={{ color: boldShade }}>
            <strong>Clinic Name: </strong> {clinic.clinicName}
          </Typography>
          <Typography variant="subtitle2" sx={{ color: boldShade }}>
            <strong>Clinic Locations: </strong>
            {locationNames()}
          </Typography>
        </Grid>
      </div>

      <div className="action-container">
        <IconButton>
          <Switch
            size={"small"}
            checked={
              clinic.status === 0 ? true : clinic.status === 1 ? false : true
            }
            onChange={handleToggle}
            inputProps={{ "aria-label": "controlled" }}
          />
        </IconButton>
        <IconButton>
          <EditIcon color="error" onClick={onEdit}></EditIcon>
        </IconButton>
        <IconButton>
          <DeleteForeverIcon
            color="error"
            onClick={onDelete}
          ></DeleteForeverIcon>
        </IconButton>
        {clinic.hasDoctorsAssigned ? (
          <IconButton>
            <ListIcon color="error" onClick={listDocs}></ListIcon>
          </IconButton>
        ) : (
          <IconButton>
            <ListIcon color={"disabled"}></ListIcon>
          </IconButton>
        )}
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="delete-modal-container">
            <div className="button-delete-modal-container">
              <DeleteForeverIcon
                style={{
                  fontSize: "60px",
                  color: "#d32f2f",
                }}
              />
            </div>

            <Typography
              variant="h5"
              sx={{
                textTransform: "capitalize",
              }}
            >
              Are you sure?
            </Typography>
            <div
              className="button-delete-modal-container"
              style={{ marginTop: "30px" }}
            >
              <Button
                style={{ width: "100px" }}
                variant="contained"
                onClick={() => {
                  const navigateback = () => {
                    return dispatch(
                      getClinicListing({
                        SearchText: "",
                        status: "",
                        PageSize: pageState.pageSize,
                        PageNumber: pageState.page,
                      })
                    );
                  };
                  const payloadData: DeleteClinicPayload = {
                    data: {
                      EncryptedClinicId: deleteActionData.encryptedClinicId,
                    },
                    callback: navigateback,
                  };
                  dispatch(deleteClinic(payloadData));
                  handleClose();
                  SetDeleteActionData({});
                }}
              >
                Confirm
              </Button>
              <Button
                style={{ width: "90px" }}
                variant="outlined"
                onClick={() => {
                  handleClose();
                  SetDeleteActionData({});
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export const ClinicByDocComponent = ({ clinicByDoc, pageState }: any) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const hrefLink = `/doctorManagement/detail/${clinicByDoc.encryptedDoctorID}`;

  return (
    <>
      <div className="details-container">
        <Grid
          container
          p={"10px"}
          sx={{ flexDirection: "column", gap: "20px" }}
          flex={3}
        >
          <Typography variant="subtitle2" sx={{ color: boldShade }}>
            <strong>Doctor Name: </strong>{" "}
            <Link to={hrefLink} style={{ color: "#1976d2" }}>
              {clinicByDoc.fullName}
            </Link>
          </Typography>
          <Typography variant="subtitle2" sx={{ color: boldShade }}>
            <strong> Location: </strong> {clinicByDoc.locationName}
          </Typography>

          <Grid container sx={{ display: "flex", gap: "10px" }}>
            {clinicByDoc.jAstatus === "Hired" ? (
              <Chip
                size="small"
                icon={<HandshakeIcon style={{ color: "#d32f2f" }} />}
                label="Hired"
                sx={{ color: "green" }}
              />
            ) : (
              <Chip
                size="small"
                icon={<ReceiptLongIcon style={{ color: "#d32f2f" }} />}
                label="Offered"
                sx={{ color: "brown" }}
              />
            )}

            {clinicByDoc.is_form_filled ? (
              <Chip
                size="small"
                icon={<MdDone size={20} />}
                label="Filled"
                className="filled"
                variant="outlined"
              />
            ) : (
              <Chip
                size="small"
                icon={<RxCross2 size={20} />}
                className="Not-filled"
                label="Not filled"
                variant="outlined"
              />
            )}
            {clinicByDoc.status ? (
              <Chip size="small" label="Active" sx={{ color: "green" }} />
            ) : (
              <Chip size="small" label="InActive" sx={{ color: "brown" }} />
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export const DoctorMobileCompoennt = ({
  doctordata,
  searchText,
  statusState,
  pageState,
  NStatusValue,
  filterByClinicId,
}: any) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [deletId, setDeletId] = useState("");

  const redirectCallback = (id: string) => {
    navigate(`/doctorManagement/${id}`);
  };
  const refreshDoctor = () => {
    const JAStatus =
      statusState === "Offered"
        ? 1
        : statusState === "Hired"
        ? 2
        : statusState === "Active"
        ? 3
        : 0;
    const newStatus =
      NStatusValue === "Active" ? 0 : NStatusValue === "Inactive" ? 1 : 2;
    const searchData = {
      firstName: searchText,
      JAStatus: JAStatus,
      PageSize: pageState.pageSize,
      PageNumber: pageState.page,
      Status: newStatus,
      clinicID: filterByClinicId,
    };
    dispatch(searchDoctor(searchData));
  };
  const onClick = (e: any) => {
    setDeletId(doctordata.encrypted_ID);
    handleOpen();
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setDeletId("");
  };
  const handleToggle = () => {
    let { encrypted_ID, status } = doctordata;
    const updatedData = {
      userID: encrypted_ID,
      status: status ? 1 : 0,
      callback: encrypted_ID,
    };
    dispatch(changeStatus(updatedData));
  };

  const onEdit = (e: any) => {
    dispatch(
      dotorEditAllApiResolve({
        encrypted_ID: doctordata.encrypted_ID,
        callback: redirectCallback,
      })
    );
  };
  const hrefLink = `/doctorManagement/detail/${doctordata.encrypted_ID}`;

  return (
    <>
      <div className="details-container">
        <Grid
          container
          p={"10px"}
          sx={{ flexDirection: "column", gap: "10px" }}
          flex={3}
        >
          <Typography variant="subtitle2" sx={{ color: boldShade }}>
            <strong> Doctor Name: </strong>{" "}
            <Link to={hrefLink} style={{ color: "#1976d2" }}>
              {doctordata.firstname} {doctordata.lastname}
            </Link>
          </Typography>

          <Grid container sx={{ display: "flex", gap: "10px" }}>
            {doctordata.jAstatus === "Hired" ? (
              <Chip
                size="small"
                icon={<HandshakeIcon style={{ color: "#d32f2f" }} />}
                label="Hired"
                sx={{ color: "green" }}
              />
            ) : (
              <Chip
                size="small"
                icon={<ReceiptLongIcon style={{ color: "#d32f2f" }} />}
                label="Offered"
                sx={{ color: "brown" }}
              />
            )}

            {doctordata.is_form_filled ? (
              <Chip
                size="small"
                icon={<MdDone size={20} style={{ color: "green" }} />}
                label="Filled"
                sx={{ color: "green" }}
              />
            ) : (
              <Chip
                size="small"
                icon={<RxCross2 size={20} style={{ color: "red" }} />}
                label="Not Filled"
                sx={{ color: "brown" }}
              />
            )}
            {doctordata.clinic_Allocated ? (
              <Chip
                size="small"
                icon={<CheckCircleIcon style={{ color: "green" }} />}
                label="Clinic Allocated"
                sx={{ color: "green" }}
              />
            ) : (
              <Chip
                size="small"
                icon={<CancelIcon style={{ color: "#d32f2f" }} />}
                label="Clinic Allocated"
                sx={{ color: "brown" }}
              />
            )}
          </Grid>
        </Grid>
      </div>

      <div className="action-container">
        <IconButton>
          <Switch
            checked={doctordata.status}
            size={"small"}
            onChange={handleToggle}
            inputProps={{ "aria-label": "controlled" }}
          />
        </IconButton>
        <IconButton>
          <EditIcon color="error" onClick={onEdit}></EditIcon>
        </IconButton>
        <IconButton>
          <DeleteForeverIcon
            color="error"
            onClick={onClick}
          ></DeleteForeverIcon>
        </IconButton>

        {doctordata.status && doctordata.is_form_filled ? (
          <IconButton>
            <AddBoxIcon
              color="error"
              onClick={() => {
                navigate(`/feeManagement/add/${doctordata.encrypted_ID}`);
              }}
            ></AddBoxIcon>
          </IconButton>
        ) : (
          <IconButton>
            <AddBoxIcon color="disabled"></AddBoxIcon>
          </IconButton>
        )}
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="delete-modal-container">
            <div className="button-delete-modal-container">
              <DeleteForeverIcon
                style={{
                  fontSize: "100px",
                  color: "#d32f2f",
                }}
              />
            </div>
            <Typography variant="h5" sx={{ textTransform: "capitalize" }}>
              Are you sure?
            </Typography>
            <div className="button-delete-modal-container">
              <Button
                style={{ width: "90px" }}
                variant="contained"
                onClick={() => {
                  dispatch(
                    deleteDoctor({ userId: deletId, callback: refreshDoctor })
                  );
                  handleClose();
                }}
              >
                Confirm
              </Button>
              <Button
                style={{ width: "90px" }}
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export const FeeManagementMobileCompoennt = ({
  feedata,
  searchText,
  statusState,
  pageState,
}: any) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [deletId, setDeletId] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setDeletId("");
  };

  const handleToggle = () => {
    let { encryptedDoctorClinicId, serviceFeesStatus } = feedata;
    let statusUpdatePayload;
    if (serviceFeesStatus === 1) {
      statusUpdatePayload = {
        callback: encryptedDoctorClinicId,
        data: {
          encryptedDoctorClinicId: encryptedDoctorClinicId,
          status: 0,
        },
      };
    } else {
      statusUpdatePayload = {
        callback: encryptedDoctorClinicId,
        data: {
          encryptedDoctorClinicId: encryptedDoctorClinicId,
          status: 1,
        },
      };
    }
    dispatch(UpdateFeeStatus(statusUpdatePayload));
  };

  const checkstate = () => {
    if (feedata?.serviceFeesStatus === 0) {
      return true;
    } else if (feedata?.serviceFeesStatus === 1) {
      return false;
    } else {
      return true;
    }
  };
  const hrefLink = `/doctorManagement/detail/${feedata.encryptedDoctorId}`;
  const onClick = () => {
    setDeletId(feedata?.encryptedDoctorClinicId);
    handleOpen();
  };

  const onEdit = () => {
    const navigateback = () => {
      return navigate(`/feeManagement/${feedata?.encryptedDoctorClinicId}`);
    };
    let editDoctorServiceAndFeesPayload = {
      callback: navigateback,
      data: {
        EncryptedDoctorClinicId: feedata?.encryptedDoctorClinicId,
      },
    };
    dispatch(feeEditAllApiResolve(editDoctorServiceAndFeesPayload));
  };
  return (
    <>
      <div className="details-container">
        <Grid
          container
          p={"10px"}
          sx={{ flexDirection: "column", gap: "10px" }}
          flex={3}
        >
          <Typography variant="subtitle2" sx={{ color: boldShade }}>
            <strong>Doctor Name: </strong>
            <Link style={{ color: "#1976d2" }} to={hrefLink}>
              {feedata?.doctorFirstName}
            </Link>
          </Typography>
          <Typography variant="subtitle2" sx={{ color: boldShade }}>
            <strong>Clinic Name: </strong>
            {feedata?.clinicName}
          </Typography>
        </Grid>
      </div>

      <div className="action-container">
        <IconButton>
          <Switch
            size={"small"}
            checked={checkstate()}
            onChange={handleToggle}
            inputProps={{ "aria-label": "controlled" }}
          />
        </IconButton>
        <IconButton>
          <EditIcon color="error" onClick={onEdit}></EditIcon>
        </IconButton>
        <IconButton>
          <DeleteForeverIcon
            color="error"
            onClick={onClick}
          ></DeleteForeverIcon>
        </IconButton>

        {!feedata.doctorStatus && feedata.isDoctorFormFilled ? (
          <IconButton>
            <AddBoxIcon
              color="error"
              onClick={() => {
                navigate(
                  `/agreementManagement/add/${feedata?.encryptedDoctorId}`
                );
              }}
            ></AddBoxIcon>
          </IconButton>
        ) : (
          <IconButton>
            <AddBoxIcon color="disabled"></AddBoxIcon>
          </IconButton>
        )}
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="delete-modal-container">
            <div className="button-delete-modal-container">
              <DeleteForeverIcon
                style={{
                  fontSize: "100px",
                  color: "#d32f2f",
                }}
              />
            </div>
            <Typography variant="h5" sx={{ textTransform: "capitalize" }}>
              Are you sure?
            </Typography>
            <div className="button-delete-modal-container">
              <Button
                style={{ width: "90px" }}
                variant="contained"
                onClick={() => {
                  const navigateback = () => {
                    return dispatch(
                      getFeesListing({
                        SearchText: "",
                        status: "",
                        PageSize: pageState.pageSize,
                        PageNumber: pageState.page,
                      })
                    );
                  };
                  let deleteServiceAndFeesPayload = {
                    callback: navigateback,
                    data: {
                      EncryptedDoctorClinicId: deletId,
                    },
                  };
                  dispatch(deleteFees(deleteServiceAndFeesPayload));
                  handleClose();
                }}
              >
                Confirm
              </Button>
              <Button
                style={{ width: "90px" }}
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export const AgreementMobileCompoennt = ({
  agreementsdata,
  searchText,
  statusState,
  pageState,
}: any) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const hrefLink = `/doctorManagement/detail/${agreementsdata.encryptedDoctorId}`;

  const addNot = (num: any) => {
    return num < 10 ? "0" + num : num;
  };

  const formatDate = (date: Date) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var d = new Date(date);
    return [addNot(d.getDate()), months[d.getMonth()], d.getFullYear()].join(
      " "
    );
  };

  const status =
    agreementsdata.agreementStatusName == "completed" ? "active" : "inactive";

  const viewPdf = (agreementUrl: any) => {
    if (agreementUrl) {
      window.open(agreementUrl, "_blank", "noopener,noreferrer");
    }
  };
  const onEdit = (e: any) => {
    dispatch(
      viewAgreementData({
        id: agreementsdata.agreementId,
        callback: viewPdf,
      })
    );
  };
  return (
    <>
      <div className="details-container">
        <Grid
          container
          p={"10px"}
          sx={{ flexDirection: "column", gap: "10px" }}
          flex={3}
        >
          <Typography variant="subtitle2" sx={{ color: boldShade }}>
            <strong> Doctor Name:</strong>
            {"  "}
            <Link style={{ color: "#1976d2" }} to={hrefLink}>
              {agreementsdata?.fullName}
            </Link>
          </Typography>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <Chip
              size="small"
              sx={{ textTransform: "capitalize" }}
              label={agreementsdata.agreementStatusName}
              className={status}
            />
            <Typography variant="subtitle2" sx={{ color: boldShade }}>
              <strong>
                <span>{formatDate(new Date(agreementsdata?.modifiedAt))}</span>
              </strong>
            </Typography>
          </Grid>
        </Grid>
      </div>

      <div className="action-container">
        <IconButton color="error">
          <DownloadIcon onClick={onEdit} />
        </IconButton>
      </div>
    </>
  );
};
