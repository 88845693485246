import {
  GET_CLINICS,
  ADD_CLINICS,
  EDIT_CLINICS,
  UPDATE_CLINIC_STATUS,
  DELETE_CLINIC,
  GET_STATES,
  GET_CLINICS_INFO,
  GET_DOC_BY_CLINICS,
  GET_CLINIC_LOC,
} from "../ActionConstants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  globalSelector,
  toggleLoader,
  toggleSnacker,
} from "../../domian/app/AppSlice";
import API from "../Api.utils";
import {
  closeLoaderPayload,
  errorOpen,
  openLoaderPayload,
  successOpen,
} from "../Api.service";
import { AddPayloadData } from "../../pages/clinicManagement/add/ClinicManagementAdd";
import { EditPayloadData } from "../../pages/clinicManagement/edit/ClinicManagementEdit";
import {
  ClinicByDocListPayload,
  DeleteClinicPayload,
  GetClinicInfoPayload,
  SearchClinicPayload,
  UpdateStatusPayload,
} from "../../pages/clinicManagement/ClinicScreen";
import { string } from "yup";
import {
  clinicStatusIsLoading,
  clinicStatusToggle,
  clinicTableIsLoading,
} from "../../pages/clinicManagement/slice/ClinicSlice";

export const getClinicListing = createAsyncThunk(
  GET_CLINICS,
  async (payload: SearchClinicPayload, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;

    let response;
    try {
      dispatch(clinicTableIsLoading(true));
      if (payload.SkipPagination === undefined) {
        response = await API.get(
          `Clinic/GetAllClinics?PageSize=${payload.PageSize}&PageNumber=${payload.PageNumber}&SearchText=${payload.SearchText}&status=${payload.status}`,
          {
            signal,
          }
        );
      } else {
        response = await API.get(
          `Clinic/GetAllClinics?SkipPagination=${payload.SkipPagination}&status=${payload.status}`,
          {
            signal,
          }
        );
      }
      // let mobileviewchecker: any = thunkApi.getState();
      const data = response.data;
      //dispatch(toggleSnacker(successOpen("successfully login")));
      return data;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Fetching Clinic Details, Please Try Again"
          )
        )
      );
      return err;
    } finally {
      dispatch(clinicTableIsLoading(false));
    }
  }
);

export const getClinicInfo = createAsyncThunk(
  GET_CLINICS_INFO,
  async (payload: GetClinicInfoPayload, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    payload.callback();
    try {
      const response = await API.get(
        `Clinic/GetClinicInfo?EncryptedClinicId=${payload.data.EncryptedClinicId}`,
        {
          signal,
        }
      );
      const data = response.data;
      //dispatch(toggleSnacker(successOpen("successfully login")));
      return data;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error, Please Try Again"
          )
        )
      );
      return err;
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);

export const getStates = createAsyncThunk(GET_STATES, async (_, thunkApi) => {
  //const token = authLogin;
  const { rejectWithValue, signal, dispatch } = thunkApi;
  try {
    const response = await API.get(`Countries/AllAustralianStates`, {
      signal,
    });
    const data = response.data;
    return data;
  } catch (err: any) {
    dispatch(
      toggleSnacker(
        errorOpen(
          err?.response?.data?.responseMessage
            ? err?.response?.data?.responseMessage
            : "Error, Please Try Again"
        )
      )
    );
    dispatch(toggleLoader(closeLoaderPayload));
    return err;
  } finally {
    dispatch(toggleLoader(closeLoaderPayload));
  }
});

export const AddClinic = createAsyncThunk(
  ADD_CLINICS,
  async (postData: AddPayloadData, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.post(`Clinic/AddClinic`, postData.data, {
        signal,
      });
      postData.callback();
      const data = response.data;
      dispatch(
        toggleSnacker(
          successOpen(
            data?.responseMessage
              ? data?.responseMessage
              : " Clinic Added Successfully "
          )
        )
      );
      return data;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Adding Clinic, Please Try Again"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return err;
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);

export const EditClinic = createAsyncThunk(
  EDIT_CLINICS,
  async (postData: EditPayloadData, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.patch(`Clinic/UpdateClinic`, postData.data, {
        signal,
      });
      postData.callback();
      const data = response.data;
      dispatch(
        toggleSnacker(
          successOpen(
            data?.responseMessage
              ? data?.responseMessage
              : "Clinic Updated Successfully"
          )
        )
      );
      return data;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Updating Clinic, Please Try Again"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return err;
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);

export const UpdateClinicStatus = createAsyncThunk(
  UPDATE_CLINIC_STATUS,
  async (postData: UpdateStatusPayload, thunkApi) => {
    //const token = authLogin;

    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(clinicStatusIsLoading(true));

      const response = await API.post(
        `Clinic/UpdateClinicStatus`,
        postData.data,
        {
          signal,
        }
      );

      dispatch(clinicStatusToggle(postData.callback));
      const data = response.data;
      dispatch(
        toggleSnacker(
          successOpen(
            data?.responseMessage
              ? data?.responseMessage
              : "Status Updated Successfully"
          )
        )
      );
      return data;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Updating Clinic Status, Please Try Again"
          )
        )
      );
      return err;
    } finally {
      dispatch(clinicStatusIsLoading(false));
    }
  }
);

export const deleteClinic = createAsyncThunk(
  DELETE_CLINIC,
  async (postData: DeleteClinicPayload, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.delete(
        `Clinic/DeleteClinic?EncryptedClinicId=${postData.data.EncryptedClinicId}`,
        {
          signal,
        }
      );
      postData.callback();
      const data = response.data;
      dispatch(
        toggleSnacker(
          successOpen(
            data?.responseMessage
              ? data?.responseMessage
              : "Clinic Deleted Successfully"
          )
        )
      );
      return data;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Deleting Clinic, Please Try Again"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return err;
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);

export const GetClinicLocations = createAsyncThunk(
  GET_CLINIC_LOC,
  async (props: any, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      const response = await API.get(
        `Clinic/GetClinicLocations?EncryptedClinicId=${props}`,
        {
          signal,
        }
      );
      const data = response.data;
      return data;
    } catch (err: any) {
      return err;
    } finally {
    }
  }
);

export const GetDoctorByClinicApiResolve = createAsyncThunk(
  "API-RESOLVE",
  async (payload: ClinicByDocListPayload, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      await Promise.all([
        dispatch(GetClinicLocations(payload.data.EncryptedClinicId)),
        dispatch(getDoctorsByClinic(payload)),
      ]);
    } catch (err: any) {
      return err;
    } finally {
    }
  }
);

export const getDoctorsByClinic = createAsyncThunk(
  GET_DOC_BY_CLINICS,
  async (payload: ClinicByDocListPayload, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(clinicTableIsLoading(true));

      // dispatch(toggleLoader(openLoaderPayload));
      let response;
      if (payload.data.PageNumber === undefined) {
        response = await API.get(
          `Clinic/GetDoctorByClinic?ID=${payload.data.EncryptedClinicId}`,
          {
            signal,
          }
        );
      } else {
        response = await API.get(
          `Clinic/GetDoctorByClinic?ID=${payload.data.EncryptedClinicId}&PageNumber=${payload.data.PageNumber}&PageSize=${payload.data.PageSize}&LocationID=${payload.data.LocationID}`,
          {
            signal,
          }
        );
      }
      return response;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Fetching Clinic Details, Please Try Again"
          )
        )
      );
      dispatch(clinicTableIsLoading(false));
      return err;
    } finally {
      dispatch(clinicTableIsLoading(false));
    }
  }
);
